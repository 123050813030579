<template>
  <div class="relative">
    <img
      ref="image"
      :src="src"
      :alt="alt"
      :class="[
        customClass,
        rounding,
        'transition-opacity duration-1000',
        { 'opacity-0': !loaded, 'opacity-100': loaded },
      ]"
      @load="onLoad"
    />
    <div
      v-if="!loaded"
      :class="[rounding]"
      class="absolute inset-0 bg-gray-200 animate-pulse"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: false,
    default: '',
  },
  customClass: {
    type: String,
    default: '',
  },
  rounding: {
    type: String,
    default: null,
  },
})

const loaded = ref(false)
const image = ref(null)

const onLoad = () => {
  loaded.value = true
}

// Ensure image reloads correctly during hydration
onMounted(() => {
  if (image.value.complete) {
    onLoad()
  } else {
    image.value.onload = onLoad
  }
})

// Watch for changes to the src prop to reload the image if needed
watch(
  () => props.src,
  () => {
    loaded.value = false
    if (image.value.complete) {
      onLoad()
    } else {
      image.value.onload = onLoad
    }
  },
)
</script>
